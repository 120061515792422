import React, { Component } from "react"
import { graphql } from 'gatsby'
import he from 'he'
import { Link } from 'gatsby'

import Seo from "../components/seo"

const CartItem = (props) => (
  <div className='cart__item'>
    <div className='cart__item__picture'>
      { props.variant.image ?
        <img src={props.variant.image.src} alt={props.title} /> :
        <img src={props.images?.length > 0 && props.images[0].src} alt={props.title}/>
      }
    </div>
    <div className='cart__item__details'>
      <div className='cart__item__product'>
        <div className="cart__item__vendor">
          {/* <span>{ props.item?.vendor }</span> */}
          {/* <span className="cart__item__vendor__price">£{ Number(props.variant.price) * props.quantity }</span> */}
        </div>
        <div className="cart__item__title">{ props.title }</div>
        { props.variant.title.indexOf('Default') === -1 && <div className="cart__item__variant">{ props.variant.title }</div> }
        <span className="cart__item__vendor__price">£{ Number(props.variant.price) * props.quantity }</span>
      </div>

      <div className='cart__item__mod'>
        <div className='cart__item__quantity'>
          <button type='button' onClick={() => props.decrementQuantity(props.id, props.quantity)}>–</button>
          <span>{ props.quantity }</span>
          <button type='button' onClick={() => props.incrementQuantity(props.id, props.quantity)}>+</button>
        </div>
        <button type='button' className='cart__item__remove' onClick={() => props.remove(props.id)}>Remove</button>
      </div>
      <div className="cart__item__price">£{ Number(props.variant.price) * props.quantity }</div>
    </div>

  </div>
)

class CartPage extends Component {

  decrementQuantity = (lineItemId, quantity) => {
    const updatedQuantity = quantity - 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity)
  }

  incrementQuantity = (lineItemId, quantity) => {
    const updatedQuantity = quantity + 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity)
  }

  render() {

    const { checkout } = this.props

    // console.log(checkout.lineItems)

    let funcs = {
      incrementQuantity: this.incrementQuantity,
      decrementQuantity: this.decrementQuantity,
      remove: this.props.removeLineItemInCart,
    }

    if (checkout.lineItems.length === 0) {
      return (
        <>
          <Seo title={he.decode('Cart')} bodyClass='cart' />
          <section className='cart__inner'>
            <p>Cart empty.</p>
            <div className='cart__buttons'>
            <Link to='/shop/collection/'>Continue Shopping</Link>
            </div>
          </section>
        </>
      )
    }

    return (
      <>
        <Seo title={he.decode('Cart')} bodyClass='cart' />
        <section className='cart__inner'>
          { checkout.lineItems.map((el, i) => {
            let item = this.props.data.allShopifyProduct.nodes.find(item => item.handle === el.variant.product.handle)
            return (<CartItem key={i} {...funcs} {...el} item={item} />)
          }) }
          <div className='cart__footer'>
            <div className='cart__totals'>
              <div className='cart__totals__title'>Subtotal (GST incl.)</div>
              <div className='cart__totals__price'>£{ parseInt(checkout.subtotalPrice) }</div>
            </div>
            <div className='cart__totals'>
              <div className='cart__totals__title'>Shipping & handling</div>
              <div className='cart__totals__price'>Calculated at next step</div>
            </div>
            <hr />
            <div className='cart__totals'>
              <div className='cart__totals__title'>Total</div>
              <div className='cart__totals__price'>£{ parseInt(checkout.totalPrice) }</div>
            </div>
          </div>

          <div className='cart__buttons'>
            <Link to='/shop/collection/'>Continue Shopping</Link>
            <a href={checkout.webUrl} target='_blank' className='checkout'>Checkout</a>
          </div>
        </section>
      </>
    )
  }
}

export const pageQuery = graphql`
  query {
    allShopifyProduct {
      nodes {
        description
        descriptionHtml
        handle
        title
        vendor
        variants {
          price
          priceNumber : price
        }
      }
    }
  }
`

export default CartPage
